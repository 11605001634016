import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

export default function Custom404() {
  const { push } = useRouter();
  useEffect(() => {
    push('/');
  }, []);
  return null;
}
